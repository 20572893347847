import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';
import { Button, OutlineButton } from 'components/atoms/Button';

export const Container = styled.div`
  b {
    font-weight: bold;
  }
  position: relative;
  font-size: 2rem;
  padding-top: 5.8rem;
`;

export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const Header = styled(Div)`
  position: absolute;
  width: 100%;
  top: 0;
  color: #000;
  height: 5.8rem;
  .tab {
    padding: 0.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .on-going {
  }
  .my-info,
  .my-counsel {
    color: #8e8e8e;
  }
  .notice {
    margin-left: auto;
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const OfferTitle = styled.h1`
  font-weight: bold;
  text-align: center;
  font-size: 3.4rem;
  margin-bottom: 0.5rem;
  line-height: 1.3;
`;
export const OfferTitleDescripttion = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 2rem;
`;

export const OfferDateInfo = styled(Div)`
  .remain {
    color: #dd4124;
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 0;
  }
  .period {
    font-size: 1.4rem;
    letter-spacing: 0;
  }
`;

export const CoinAnimation = styled.div`
  img {
    margin: 0 auto;
    width: 100%;
    max-width: 414px;
  }
`;
export const OfferSummaryTable = styled.div`
  margin-top: 8rem;
  .price {
    text-align: right;
    .rect {
      display: inline-block;
      width: 2.2rem;
      height: 1rem;
      margin-left: 3px;
      background-color: #e1e3e2;
    }
  }
`;

export const OfferImageWrap = styled.div`
  position: relative;
  margin: 11rem -2rem 0;
  img {
    width: 100%;
  }
  .waypoint {
    position: absolute;
    top: 500px;
  }
`;

export const UserName = styled(Div)`
  font-size: 3.4rem;
`;

export const UserPhone = styled(Div)`
  font-size: 1.4rem;
`;

export const Tabs = styled(Div)`
  margin: 0 -0.6rem;
`;

export const ButtonWrap = styled(Div)`
  width: 100%;
  margin-top: 2rem;
`;

const BlackButton = css`
  background-color: #000;
  border: none;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

export const OfferButton = styled(Button)`
  border-color: #000;
  color: #000;
`;

export const OfferShareButton = styled(OutlineButton)`
  border-color: #000;
`;

export const FixedBottom = styled(Div)`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  z-index: 9999;
  button {
    color: #fff;
    background-color: #000;
    font-size: 1.4rem;
    height: 6.4rem;
  }
`;

export const StampButton = styled(Button)`
  background-color: #000;
  border: none;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

export const InviteButton = styled(Button)`
  margin-top: 2rem;
  border-color: #fff;
`;

export const TitleBig = styled.h1`
  font-size: 4rem;
`;

export const Image = styled.div``;

export const Title = styled.div``;

export const Description = styled.div``;

export const HospitalName = styled.div``;

export const Count = styled.div``;
