import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';
import { Button } from 'components/atoms/Button';

export const Error = styled.div`
  font-size: 1.2rem;
  color: #dd4124;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 2.2rem;
`;

export const InputGroup = styled(Div)`
  position: relative;
  margin-bottom: 3rem;
  font-size: 1.3rem;
  color: #000;
  .label-wrap {
    margin-bottom: 1rem;
    label {
      font-size: 1.4rem;
    }
  }
  input {
    height: 5.6rem;
    width: 100%;
    padding: 0 1.4rem;
    border-radius: 4px;
    border: solid 1px ${(props) => (props.error ? '#dd4124' : '#e1e3e2')};
    &::placeholder {
      color: #b1b3b2;
    }
  }
  select {
    font-size: 1.3rem;
    height: 5.6rem;
    width: 100%;
    padding: 0 1.4rem;
    border-radius: 4px;
    border: solid 1px #e1e3e2;
    &:invalid {
      color: #b1b3b2;
    }
    option {
      color: #000;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  font-size: 2rem;
  padding-top: 8.8rem;
`;

export const Header = styled(Div)`
  position: absolute;
  width: 100%;
  top: 0;
  color: #000;
  height: 5.8rem;
  .tab {
    padding: 0.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .on-going {
  }
  .my-info,
  .my-counsel {
    color: #8e8e8e;
  }
  .notice {
    margin-left: auto;
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const OfferTitle = styled.div`
  font-weight: bold;
  font-size: 2.4rem;
  margin-bottom: 0.5rem;
  line-height: 1.33;
`;
export const OfferTitleDescription = styled.div`
  font-size: 1.4rem;
  margin-bottom: 6rem;
`;

export const OfferDateInfo = styled(Div)`
  .remain {
    color: #dd4124;
    font-weight: 600;
    font-size: 1.8rem;
  }
`;

export const OfferInputWrap = styled(Div)`
  input {
  }
`;

export const OfferInputGroup = styled(Div)`
  flex: 1 1 0;
`;
export const ButtonWrap = styled(Div)`
  width: 100%;
  margin-top: 2rem;
`;

const BlackButton = css`
  background-color: #000;
  border: none;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

const DisabledButton = css`
  background-color: #e1e3e2;
`;

export const InfoButton = styled(Button)`
  color: #000;
  margin-bottom: 1rem;
  ${(props) => props.bgBlack && BlackButton}
  ${(props) => props.disabled && DisabledButton}
  button {
    &:disabled {
      color: #fff;
    }
  }
`;

export const StampButton = styled(Button)`
  background-color: #000;
  border: none;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

export const InviteButton = styled(Button)`
  margin-top: 2rem;
  border-color: #fff;
`;

export const TitleBig = styled.h1`
  font-size: 4rem;
`;

export const Image = styled.div``;

export const Title = styled.div``;

export const Description = styled.div``;

export const HospitalName = styled.div``;

export const Count = styled.div``;
