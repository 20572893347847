import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { PrevHeader } from 'components';
import * as Styled from './index.styles';
// import { pixelEvent } from '@helper/pixel';
import { pixelEvent } from 'utils/pixel';
import UsingStoreForm from './form';
import { useSelector } from 'react-redux';

const Index = () => {
  const router = useRouter();
  const scroll = useRef(null);
  const { OFFER_FORM } = useSelector((state) => state.form);

  useEffect(() => {
    pixelEvent('Lead', {
      currency: 'KRW',
      value: 10000, // db단가가 들어가야 하는데 현재 없음
    });
    scroll.current.scrollTo(0, 0);
    window.scrollTo(0, 0);
    if (!OFFER_FORM) {
      router.back();
      return <></>;
    }
  }, []);

  return (
    <Styled.Container ref={scroll}>
      <Styled.Header d-flex align-items-center>
        <PrevHeader />
      </Styled.Header>
      <div>
        <Styled.OfferTitle>
          오퍼 참여를 위한 정보를
          <br />
          입력해주세요
        </Styled.OfferTitle>
        <Styled.OfferTitleDescription>
          작성하신 연락처로 오퍼 결과를 알려드려요!
        </Styled.OfferTitleDescription>

        <Styled.OfferInputWrap d-flex align-items-center justify-content-between>
          <Styled.OfferInputGroup>
            <UsingStoreForm />
          </Styled.OfferInputGroup>
        </Styled.OfferInputWrap>
      </div>
    </Styled.Container>
  );
};

export default Index;
