import styled from 'styled-components';

export const Term = styled.button`
  width: 100%;
  height: 6.4rem;
  background-color: #fff;
  border: 1.5px solid #e1e3e2;
  border-color: ${({ term }) => term && '#033e52'};
  color: ${({ term }) => (term ? 'inherit' : '#e1e3e2')};
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Span = styled.span`
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: ${({ isUnderline }) => isUnderline && 'underline'};
`;
