import React, { useEffect } from 'react';
import * as Styled from './index.styles';
import { CloseEdgeButton } from 'components/atoms/Button';

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Styled.Container>
      <Styled.TermsHeaderWrap>
        <Styled.TermsHeader d-flex align-items-center justify-content-between>
          <div />
          <CloseEdgeButton />
        </Styled.TermsHeader>
      </Styled.TermsHeaderWrap>
      <Styled.TermsTitleWrap>
        <Styled.TermsTitle>개인정보 수집・이용 동의</Styled.TermsTitle>
        {/* <Styled.TermsDescription>
              오닥터는 의료법을 준수합니다 :D
            </Styled.TermsDescription> */}
      </Styled.TermsTitleWrap>
      <Styled.TermsWrap>
        1) 수집・이용 주체
        <br />
        해당 오퍼의 주체 병원
        <br />
        2) 수집・이용 목적
        <br />
        오퍼 선정결과 안내, 신청한 오퍼의 상담 안내를 위한 전화 및 문자 연락과 방문예약 등 신청자
        관리
        <br />
        3) 수집・이용 항목
        <br />
        이름, 연락처, 오퍼 제시금액
        <br />
        4) 보유・이용 기간
        <br />
        상담 안내 종료와 방문예약 완료 시까지 (이용자 요청 시, 즉시 파기)
        <br />
      </Styled.TermsWrap>
      <Styled.TermsTitleWrap>
        <Styled.TermsTitle>개인정보 처리위탁 동의</Styled.TermsTitle>
        {/* <Styled.TermsDescription>
              오닥터는 의료법을 준수합니다 :D
            </Styled.TermsDescription> */}
      </Styled.TermsTitleWrap>
      <Styled.TermsWrap>
        1) 처리위탁을 받는 자 (수탁자)
        <br />
        오닥터
        <br />
        2) 처리위탁 업무 내용
        <br />
        온라인을 통한 고객 개인정보의 수집・보관・파기 관리- 고객 개인정보 서버의 운영 관리
        <br />
      </Styled.TermsWrap>
    </Styled.Container>
  );
};

export default Index;
