import { useEffect, useState, useCallback } from 'react';

const baseOption = {
  root: null,
  threshold: 0.5,
  rootMargin: '50px',
};

export const useIntersecting = function (onIntersect, option, unIntersect) {
  const [ref, setRef] = useState(null);

  const checkIntersect = useCallback(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        onIntersect(entry, observer);
      } else {
        if (unIntersect) {
          unIntersect();
        }
      }
    },
    [onIntersect, unIntersect]
  );

  useEffect(() => {
    if (!ref) return;
    const observer = new IntersectionObserver(checkIntersect, {
      ...baseOption,
      ...option,
    });

    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref, checkIntersect, option]);

  return [ref, setRef];
};
