import React, { useState, useEffect, useRef } from 'react';
import * as Styled from './index.styles';
import { useIntersecting } from 'hooks/useObserver';

const Index = ({ onClick }) => {
  const button = useRef(null);
  const [ref, setRef] = useIntersecting(
    async (entry, observer) => {
      // observer.unobserve(entry.target);
      // observer.observe(entry.target);
      button.current.style.display = 'none';
    },
    {},
    () => {
      button.current.style.display = 'block';
    }
  );

  return (
    <>
      <div className="waypoint" ref={setRef} />
      {true && (
        <div ref={button} style={{ display: 'none' }}>
          <Styled.FixedBottom>
            <Styled.OfferButton bgBlack onClick={onClick}>
              원하는 가격 입력하기
            </Styled.OfferButton>
          </Styled.FixedBottom>
        </div>
      )}
    </>
  );
};

export default Index;
