import { useDispatch } from 'react-redux';
import { useQuery, useLazyQuery } from '@apollo/client';

import { GET_PAYMENTS } from 'graphql/philing';
// import { getCollection, getEvent } from '../modules/offer/offer.actions';

export default function usePayments() {
  const options = {
    variables: {},
  };

  const [fetchData, { called, loading, data, refetch }] = useLazyQuery(GET_PAYMENTS, options);

  const isFetching = !(!loading && called);

  return [fetchData, isFetching, data, refetch];
}
