import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';
import { Button } from 'components/atoms/Button';

export const FakeBackground = styled(Div)`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #dd4124;
  z-index: -1;
  left: 0;
  top: 0;
`;

export const InputGroup = styled(Div)`
  margin-bottom: 3rem;
  font-size: 1.3rem;
  color: #000;
  .label-wrap {
    margin-bottom: 1rem;
    label {
      font-size: 1.4rem;
    }
  }
  input {
    height: 5.6rem;
    width: 100%;
    padding: 0 1.4rem;
    border-radius: 4px;
    border: solid 1px #e1e3e2;
  }
`;

export const Container = styled.div`
  position: relative;
  font-size: 2rem;
  padding-top: 8.8rem;
  color: #fff;
  height: 100vh;
`;

export const DecoOfferDone = styled.div`
  width: 31rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: -2rem;
  background-image: url(${(props) => props.bgImg});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ImageOfferDone = styled.img`
  position: absolute;
  transition: all 0.8s ease;
  top: 0;
  left: -2rem;
  transform: ${({ scale }) => (scale ? 'scale(1)' : 'scale(0)')};
`;

export const Header = styled(Div)`
  position: absolute;
  width: 100%;
  top: 0;
  color: #000;
  height: 5.8rem;
  .tab {
    padding: 0.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .on-going {
  }
  .my-info,
  .my-counsel {
    color: #8e8e8e;
  }
  .notice {
    margin-left: auto;
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const OfferDoneTitle = styled.div`
  font-weight: bold;
  font-size: 3.4rem;
  margin-bottom: 0.5rem;
  line-height: 1.33;
`;
export const OfferDoneTitleDescript = styled.div`
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: space-between;
`;

export const OfferDateInfo = styled(Div)`
  .remain {
    color: #dd4124;
    font-weight: 600;
    font-size: 1.8rem;
  }
`;

export const ButtonWrap = styled(Div)`
  width: 100%;
  margin-top: 2rem;
`;

const BlackButton = css`
  background-color: #000;
  border: none;
  height: 5.6rem;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

export const FixedBottom = styled(Div)`
  width: 100%;
  max-width: 420px;
  margin: 0 -2rem;
  padding: 0 2rem;
  position: fixed;
  bottom: 3rem;
  text-align: center;
  z-index: 999;
  button {
    color: #fff;
    background-color: #000;
    font-size: 1.4rem;
    height: 6.4rem;
    border-radius: 4px;
  }
`;

export const DoneButton = styled(Button)`
  border-color: #000;
  color: #000;
  margin-bottom: 1rem;
  ${(props) => props.bgBlack && BlackButton}
`;

export const StampButton = styled(Button)`
  background-color: #000;
  border: none;
  height: 5.6rem;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

export const InviteButton = styled(Button)`
  margin-top: 2rem;
  border-color: #fff;
`;

export const TitleBig = styled.h1`
  font-size: 4rem;
`;

export const Image = styled.div``;

export const Title = styled.div``;

export const Description = styled.div``;

export const HospitalName = styled.div``;

export const Count = styled.div``;
