import React from 'react';
import useStoreForm from 'hooks/useForm';
import * as Styled from './index.styles';
import Button, { OfferAgreementButton } from 'components/molecules/ButtonOfferAgreeButton';
import SelectBox from 'components/atoms/SelectBox';
import { useSelector } from 'react-redux';
import { isPhoneNumeric, isNumeric, isName } from 'utils/validate';

const ageGroupData = [
  {
    label: '10대 미만',
    value: 'underTen',
  },
  {
    label: '10대',
    value: 'teenager',
  },
  {
    label: '20대',
    value: 'twenties',
  },
  {
    label: '30대',
    value: 'thirties',
  },
  {
    label: '40대',
    value: 'forties',
  },
  {
    label: '50대',
    value: 'fifties',
  },
  {
    label: '60대 이상',
    value: 'overSixties',
  },
];

const ErrorComponents = ({ error }) => {
  let msg = '';

  switch (error) {
    case 'name':
      msg = '본인 확인을 위해 올바른 이름을 입력해주세요.';
      break;
    case 'phone':
      msg = '오퍼 결과 안내를 위해 올바른 연락처를 입력해주세요.';
      break;
    default:
      msg = null;
      break;
  }

  return msg && <Styled.Error>{msg}</Styled.Error>;
};

export default function UsingStoreForm({ onSubmit, validation }) {
  const { values, handleChange, handleSubmit } = useStoreForm('OFFER_FORM', onSubmit);

  const { username, userPhonenum, ageGroup, terms, error, price } = values.OFFER_FORM;
  // const {
  //   OFFER_FORM
  // } = useSelector((state) => state.form);

  const handleInputsChange = ({ target: { name, value } }) => {
    validation(name, value);

    if (name === 'userPhonenum' && !isNumeric(value)) {
      return;
    }

    handleChange(name, value);
  };

  const handleCheckboxesChange = ({ target: { name, checked } }) => {
    console.log(name, checked);
    // handleChange(name, checked);
  };

  const handleSelectChange = ({ target: { name, value } }) => {
    handleChange(name, value);
  };

  const handleTerms = (name, checked) => {
    // console.log(name, checked);
    handleChange('terms', !values.OFFER_FORM.terms);
  };

  return (
    <>
      <Styled.InputGroup error={error?.name}>
        <div className="label-wrap">
          <label htmlFor="username">이름</label>
        </div>
        <input
          type="text"
          name="username"
          value={username}
          placeholder="이름을 입력해주세요."
          onChange={handleInputsChange}
        />
        {error?.name && <ErrorComponents error="name" />}
      </Styled.InputGroup>

      <Styled.InputGroup error={error?.ageGroup}>
        <div className="label-wrap">
          <label htmlFor="ageGroup">연령대</label>
        </div>
        <SelectBox required name="ageGroup" onChange={handleSelectChange} value={ageGroup}>
          <option value="" selected disabled hidden>
            연령대를 선택해주세요.
          </option>
          {ageGroupData.map((age, i) => (
            <option key={i} value={age.value}>
              {age.label}
            </option>
          ))}
        </SelectBox>
        {/* <select
          required
          name="ageGroup"
          onChange={handleSelectChange}
          value={ageGroup}
        >
          <option value="" selected disabled hidden>연령대를 선택해주세요.</option>
          {
            ageGroupData.map((age, i) => (<option key={i} value={age.value}>{age.label}</option>))
          }
        </select> */}
        {/* <input
          type="text"
          name="ageGroup"
          value={ageGroup}
          onChange={handleInputsChange}
        /> */}
      </Styled.InputGroup>

      <Styled.InputGroup error={error?.phone}>
        <div className="label-wrap">
          <label htmlFor="userPhonenum">연락처</label>
        </div>
        <input
          type="tel"
          name="userPhonenum"
          pattern="[0-9]*"
          inputMode="numeric"
          value={userPhonenum}
          placeholder="연락처를 입력해주세요."
          onChange={handleInputsChange}
        />
        {error?.phone && <ErrorComponents error="phone" />}
      </Styled.InputGroup>

      <div style={{ marginBottom: '2rem', marginTop: '6rem' }}>
        <OfferAgreementButton handleTerm={handleTerms} term={values.OFFER_FORM.terms} />
      </div>

      <div style={{ marginBottom: '6rem' }}>
        <Styled.InfoButton
          disabled={!(terms && username && userPhonenum && ageGroup)}
          bgBlack
          onClick={() => {
            handleSubmit();
            localStorage.setItem('offerPrice', price);
          }}
        >
          오퍼 완료
        </Styled.InfoButton>
      </div>
    </>
  );
}
