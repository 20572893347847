import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import {
  addForm,
  errorNameFormat,
  errorPhoneFormat,
  errorInfoInput,
} from 'modules/form/form.actions';
import UsingStoreFormChild from './formChild';
import useOfferMutation from 'hooks/useOfferMutation';
import { isPhoneNumeric, isName } from 'utils/validate';
import { getQueryStringParams as qs } from 'utils/query-string';

export default function UsingStoreForm() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { utm_campaign, utm_source, utm_medium } = router.query;
  const initialState = {
    username: null,
    ageGroup: null,
    userPhonenum: null,
    error: {
      name: false,
      phone: false,
    },
  };

  const { OFFER_FORM } = useSelector((state) => state.form);
  const { terms, error, ...payload } = OFFER_FORM;

  const [offerMutation] = useOfferMutation({
    ...payload,
    ...(utm_campaign && { utmCampaign: utm_campaign }),
    ...(utm_source && { utmSource: utm_source }),
    ...(utm_medium && { utmMedium: utm_medium }),
  });

  const submit = useCallback(() => {
    offerMutation();
  }, [offerMutation]);

  // dispatch(
  //   addForm({
  //     key: 'OFFER_FORM',
  //     initialState,
  //   })
  // );

  const onSubmit = (values) => {
    if (Object.values(values.OFFER_FORM.error).indexOf(true) > -1) {
      return;
    }

    submit();
    // dispatch({
    //   type: 'FETCH_OFFER_APPLY',
    //   payload: values
    // });
  };

  const validation = (name, values) => {
    const error = {};

    switch (name) {
      case 'userPhonenum':
        // phonenumber error
        error.phone = !isPhoneNumeric(values);
        break;
      case 'username':
        // username error
        error.name = !isName(values);
        break;
      default:
        break;
    }

    dispatch(
      errorInfoInput({
        key: 'OFFER_FORM',
        value: error,
      })
    );
  };

  return <UsingStoreFormChild onSubmit={onSubmit} validation={validation} />;
}
