import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addForm, changeFormValue } from '../modules/form/form.actions';
// import invariant from 'invariant';

// const selectFormByKey = (key) => (state) => state.form[key];
const selectFormByKey = (key) => (state) => state.form;

export default function useStoreForm(key = '', submitCb) {
  const values = useSelector(selectFormByKey(key));

  // invariant(
  //   values,
  //   `Unable to find '${key}' form. You should dispatch 'ADD_FORM' action IN A PARENT CONTAINER before interacting with a form (to increase perfs)`
  // );

  const dispatch = useDispatch();
  const handleChange = (name, value) => {
    dispatch(changeFormValue({ key, name, value }));
  };
  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    submitCb({ ...values });
  };

  return {
    values,
    handleChange: useCallback(handleChange, []),
    handleSubmit,
  };
}
