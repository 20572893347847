import dynamic from 'next/dynamic';

// atoms
// ---
export { default as LazyImage } from './atoms/LazyImage';
export { default as PrevHeader } from './atoms/PrevHeader';
export { RatioBox } from './atoms/Image';

// molecules
// ---
export { default as CardSelectable } from './molecules/Card/CardSelectable';
export { default as Modal } from './molecules/Modal';
export { default as PrivacyTerms } from './molecules/Terms/Privacy';
export { default as UseageTerms } from './molecules/Terms/UseAge';

// organisms
// ---
export { default as SiteLayout } from './organisms/Layout/SiteLayout';
export { default as PickLayout } from './organisms/Layout/PickLayout';
export { default as Navigator } from './organisms/Navigator';
export { default as ReviewBoard } from './organisms/ReviewBoard';
export { default as CountDown } from './organisms/CountDown';
export { default as Paper } from './organisms/Paper';

// templates
// ---
export { default as Package } from './templates/Package';
export { default as ConfirmEvent } from './templates/ConfirmEvent';

export { default as Offer } from './templates/Offer/Main';
export { default as OfferDeal } from './templates/Offer/Deal';
export { default as OfferInfo } from './templates/Offer/Info';
export { default as OfferDone } from './templates/Offer/Done';
export { default as OfferTerm } from './templates/Offer/Term';

// export { default as Quiz } from './templates/Quiz/Main';
// export { default as QuizQuestion } from './templates/Quiz/Question';
// export { default as QuizNotice } from './templates/Quiz/Notice';
// export { default as QuizPass } from './templates/Quiz/Pass';
// export { default as QuizFailure } from './templates/Quiz/Failure';

export { default as MyInfo } from './templates/MyPage/MyInfo';
export { default as MyPoint } from './templates/MyPage/MyPoint';
export { default as MyReward } from './templates/MyPage/RewardItemDetail';

export const Versus = dynamic(() => import('./templates/Versus'));
export const VersusDetail = dynamic(() => import('./templates/Versus/detail'));
export const CommentDetail = dynamic(() => import('./templates/Versus/CommentDetail'));

export const NotFound = dynamic(() => import('./templates/NotFound'));

// v2 philing 비대면
export * from './molecules/Question';
