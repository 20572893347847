import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { getOfferList } from 'graphql/offer';
// import { getCollection, getEvent } from '../modules/offer/offer.actions';

export default function useOffer() {
  const dispatch = useDispatch();

  const optionEvent = {
    onCompleted: (res) => {
      // dispatch(getEvent.success(res.event));
    },
  };

  const [fetchEvent, { called, loading, data }] = useLazyQuery(getOfferList, optionEvent);

  const isFetching = !(!loading && called);

  return [fetchEvent, isFetching, data];
}
