import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

import { getSymptom } from 'graphql/philing';
// import { getCollection, getEvent } from '../modules/offer/offer.actions';

export default function useSymptom({ symptomId }) {
  const options = {
    variables: {
      id: symptomId,
    },
  };
  const { data, loading } = useQuery(getSymptom, options);

  return { data, loading };
}
