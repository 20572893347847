import React from 'react';
import useStoreForm from 'hooks/useForm';
import * as Styled from './index.styles';
import { numberWithCommas } from 'utils/number';
import { isNumeric } from 'utils/validate';

const ErrorComponents = ({ error }) => {
  let msg = '';

  switch (true) {
    case error.exceed:
      msg = '최저가 최고가 사이로 입력해주세요.';
      break;
    case error.unit:
      msg = '100원 단위로 입력해주세요';
      break;
    default:
      msg = null;
      break;
  }

  return msg && <Styled.Error>{msg}</Styled.Error>;
};
export default function UsingStoreForm({ onSubmit, validation }) {
  const { values, handleChange, handleSubmit } = useStoreForm('OFFER_FORM', onSubmit);

  const { price, error } = values.OFFER_FORM;

  const handleInputsChange = ({ target, target: { name, value, selectionEnd } }) => {
    const convertValue = value.replace(/,+/g, '');

    validation(convertValue);
    if (isNumeric(convertValue)) {
      localStorage.setItem('offerPrice', convertValue);
      handleChange(name, Number(convertValue));

      setTimeout(() => {
        const startPosition = value.length - selectionEnd;
        const len =
          Math.max(convertValue.length - startPosition, 0) +
          numberWithCommas(convertValue).split(',').length -
          1;
        target.setSelectionRange(len, len);
      }, 10);
    }
  };

  const handleCheckboxesChange = ({ target: { name, checked } }) => {
    handleChange(name, checked);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        flex: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Styled.InputGroup zeroColor={!price || price == '00'} position-relative>
        <div className="currency">￦</div>
        <div className="label-wrap">
          <label
            style={{
              position: 'absolute',
              left: '-10000px',
              top: 'auto',
              height: '1px',
              overflow: 'hidden',
            }}
            htmlFor="name"
          >
            오퍼 희망 가격
          </label>
        </div>
        <input
          type="text"
          name="price"
          /* pattern="[0-9]*" */
          inputMode="numeric"
          placeholder="00"
          value={numberWithCommas(price)}
          onChange={handleInputsChange}
        />
        <ErrorComponents error={error} />
      </Styled.InputGroup>

      <div>
        <Styled.BasicButton
          bgBlack
          disabled={Object.values(values.OFFER_FORM.error).indexOf(true) > -1}
          // onClick={() => { onSubmit(price); }}
        >
          입력 완료
        </Styled.BasicButton>
      </div>
    </form>
  );
}
