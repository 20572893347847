import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import {
  addForm,
  errorPriceClear,
  errorPriceExceed,
  errorPriceUnit,
  errorPriceInput,
} from 'modules/form/form.actions';
import UsingStoreFormChild from './formChild';
import { confirm } from 'components/molecules/Modal';

export default function UsingStoreForm({ minPrice, maxPrice, offer, avg }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const initialState = {
    price: '00',
    offerId: offer.id,
    terms: false,
    error: {
      unit: true,
      exceed: false,
      name: false,
      phone: false,
    },
  };

  const pricePopup = async (op) => {
    const c = await confirm({
      ...op,
    });

    return c;
  };

  dispatch(
    addForm({
      key: 'OFFER_FORM',
      initialState,
    })
  );

  const onSubmit = async (values) => {
    if (Object.values(values.OFFER_FORM.error).indexOf(true) > -1) {
      return;
    }

    // 평균값 체크
    if (avg > values.OFFER_FORM.price) {
      const op = {
        text: '평균보다 낮게 오퍼하면 선정되지 않을 가능성이 높아요. 가격을 바꿔 오퍼하시겠어요?',
        button: {
          cancel: {
            text: '그대로 오퍼하기',
          },
          ok: {
            text: '조정하기',
            callback: () => {},
          },
        },
      };

      if (await pricePopup(op)) {
        return;
      }
    }

    router.push({
      pathname: `/offer/${offer.id}/info`,
      search: window.location.search,
    });
  };

  const priceValidation = (values) => minPrice <= values && values <= maxPrice;

  const validation = (values) => {
    const error = {};
    if (!(minPrice <= values && values <= maxPrice)) {
      // 입력 가격 범위 초과
      error.exceed = true;
    }

    if (values % 100) {
      // 입력 가격 100원 단위 오류
      error.unit = true;
    }

    dispatch(
      errorPriceInput({
        key: 'OFFER_FORM',
        value: error,
      })
    );
  };

  return (
    <UsingStoreFormChild
      onSubmit={onSubmit}
      validation={validation}
      priceValidation={priceValidation}
    />
  );
}
