import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { CountDown, PrevHeader } from 'components';
import * as Styled from './index.styles';
import { useUser } from 'hooks/useUser';
import { webShare } from 'utils/webShare';
import { convertBreakLine, convertBoldLine, convertStyleLine } from 'utils/common';
import { pixelEvent } from 'utils/pixel';
import Table from 'components/molecules/Table';
import useOffer from 'hooks/useOffer';
import dayjs from 'dayjs';
import shuffle from 'lodash/shuffle';
import 'dayjs/locale/ko';
import BotButton from './bottomButton';
import { confirm } from 'components/molecules/Modal';

const Index = () => {
  const router = useRouter();
  const { user } = useUser();
  const scroll = useRef(null);
  const OfferCoinAnimation = '/assets/images/ani/Coin_V1_002.gif';

  const [fetchEvent, isFetching, data] = useOffer();

  useEffect(() => {
    pixelEvent('ViewContent', {
      content_type: 'product',
      content_ids: 2, // 오퍼아이디
      product: '2_Offer',
    });
    fetchEvent();
    scroll.current.scrollTo(0, 0);
  }, [fetchEvent]);

  useEffect(() => {
    if (!isFetching && data?.offers.length === 0) {
      // immediately invoked
      (async () => {
        const result = await confirm({
          text: '현재 진행중인 오퍼가 없습니다.\n다음 오퍼를 기다려주세요!',
          button: {
            cancel: false,
            ok: {
              text: '확인',
            },
          },
        });

        if (result) {
          router.back();
        }
      })();
    }
  }, [isFetching, data, router]);

  const offerHeader = (applyViewCount) => [
    {
      name: 'username',
      value: `${applyViewCount}명이 오퍼중`,
      class: null,
    },
    {
      name: 'price',
      value: null,
      class: 'price',
    },
  ];

  const offerListMapper = (recentOfferUsers) =>
    recentOfferUsers.map((u) => ({
      username: `${u.username}**(${u.userPhonenum})`,
      price: `${u.price}$pr원 오퍼!`,
    }));

  const handleHistoryBack = () => router.push('/pick');

  return (
    <Styled.Container ref={scroll}>
      <Styled.Header id="header" d-flex align-items-center>
        <PrevHeader onClick={handleHistoryBack} />
        <div
          className="notice"
          onClick={() => {
            // router.push({ pathname: '/offer/notice', search: location.search,})
            window.open('https://www.notion.so/e4c87926c9d44aee9a8ca4a72d494db2', '_blank');
          }}
        >
          오퍼 안내
        </div>
      </Styled.Header>
      {isFetching ? (
        <div />
      ) : (
        <div>
          {data?.offers.map((offer, i) => (
            <>
              <CountDown endOfOffer={offer.endDate} />
              <React.Fragment key={i}>
                <Styled.OfferTitle style={{ marginTop: '3rem' }}>
                  {convertBreakLine(offer.title, (text, i) => (
                    <React.Fragment key={i}>
                      {convertStyleLine(text, (line, idx) =>
                        idx % 2 ? (
                          <React.Fragment key={idx}>
                            <b style={{ color: '#dd4124' }}>{line}</b>
                          </React.Fragment>
                        ) : (
                          line
                        )
                      )}
                      <br />
                    </React.Fragment>
                  ))}
                </Styled.OfferTitle>
                <Styled.OfferTitleDescripttion>
                  {convertBreakLine(offer.description, (text, i) => (
                    <React.Fragment key={i}>
                      {convertBoldLine(text, (line, idx) =>
                        idx % 2 ? (
                          <React.Fragment key={idx}>
                            <b>{line}</b>
                          </React.Fragment>
                        ) : (
                          line
                        )
                      )}
                      <br />
                    </React.Fragment>
                  ))}
                </Styled.OfferTitleDescripttion>
                <Styled.OfferDateInfo d-flex flex-column align-items-center>
                  {/* <div className="remain">
                  D-{getDDay(offer.endDate)}{' '}
                  <DayTimerComponent
                    init={convertDateToTime(offer.endDate)}
                    onTimeOut={() => {
                      alert('오퍼가 종료되었습니다.');
                      router.push('/');
                    }}
                  />
                </div> */}
                  <div className="period">
                    {dayjs(offer.startDate).format('YYYY.MM.DD')}~
                    {dayjs(offer.endDate).format('YYYY.MM.DD')}
                  </div>
                </Styled.OfferDateInfo>

                <Styled.CoinAnimation>
                  <img src={OfferCoinAnimation} alt="동전 애니메이션" />
                </Styled.CoinAnimation>

                <div>
                  <Styled.OfferButton
                    style={{ marginBottom: '1rem' }}
                    bgBlack
                    onClick={() => {
                      router.push({
                        pathname: `/offer/${offer.id}/deal`,
                        search: location.search,
                        // query: { data: JSON.stringify(offer) },
                      });
                    }}
                  >
                    원하는 가격 입력하기
                  </Styled.OfferButton>
                  <BotButton
                    onClick={() => {
                      router.push({
                        pathname: `/offer/${offer.id}/deal`,
                        search: location.search,
                        state: { offer },
                      });
                    }}
                  />
                  <Styled.OfferShareButton
                    onClick={() => {
                      webShare({
                        shareUrl: `${window.location.origin}/offer/${offer.id}`,
                        referralCode: user?.referralCode || null,
                      });
                    }}
                  >
                    친구에게 공유하기
                  </Styled.OfferShareButton>
                </div>

                <Styled.OfferSummaryTable>
                  <Table
                    abcList={offerHeader(offer.applyViewCount)}
                    list={offerListMapper(shuffle(offer.recentOfferUsers).slice(0, 3))}
                  />
                </Styled.OfferSummaryTable>
                <Styled.OfferImageWrap>
                  {offer.contentsImageUrls.map((img, idx) => (
                    <img key={idx} src={img} alt="오퍼 이미지" />
                  ))}
                </Styled.OfferImageWrap>
              </React.Fragment>
            </>
          ))}
        </div>
      )}
    </Styled.Container>
  );
};

export default Index;
