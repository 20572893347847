import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { CountDown, PrevHeader } from 'components';
import { DayTimerComponent } from 'components/molecules/Timer';
import * as Styled from './index.styles';
import { useQuery } from '@apollo/client';
import { convertBreakLine, convertBoldLine, convertStyleLine } from 'utils/common';
import { numberWithCommas } from 'utils/number';
import { webShare } from 'utils/webShare';
import { getDDay, convertDateToTime } from 'utils/date';
import { pixelEvent } from 'utils/pixel';
import UsingStoreForm from './form';

const OfferBar = ({ avg, minPrice, maxPrice }) => {
  const { OFFER_FORM } = useSelector((state) => state.form);

  const rate = Math.min(((OFFER_FORM?.price - minPrice || 0) / (maxPrice - minPrice)) * 100, 100);
  const avgRate = Math.min(((avg - minPrice || 0) / (maxPrice - minPrice)) * 100, 100);
  const exceed = !(minPrice <= OFFER_FORM?.price && OFFER_FORM?.price <= maxPrice);
  const HundredUnit = OFFER_FORM?.price % 100;

  return (
    <>
      <Styled.BarAnimation avg={avg} rate={rate} avgRate={avgRate} error={exceed || HundredUnit}>
        <div className="progress-bar-container">
          <div className="progress-bar hidden">
            <div className="progress-bar-avg" style={{ height: '0px' }}>
              <div
                className="pos-relative"
                style={{
                  minWidth: '10rem',
                  width: '100%',
                }}
              >
                <span
                  className="avg-offer-price"
                  style={{
                    fontSize: '1.2rem',
                    color: '#b1b3b2',
                    top: '-2.4rem',
                    fontWeight: 600,
                    position: 'absolute',
                    textAlign: 'center',
                    right: 0,
                  }}
                >
                  평균 오퍼&nbsp;{numberWithCommas(avg)}
                </span>
              </div>
            </div>
          </div>

          {/* <div className="progress-bar stripes">
                <span className="progress-bar-inner" />
              </div> */}
          <div className="progress-bar animated reverse slower border-radius">
            <div className="progress-bar-avg stripes">
              <div
                className="pos-relative"
                style={{
                  minWidth: '10rem',
                  width: '100%',
                }}
              >
                {/* <span
                  className="avg-offer-price"
                  style={{
                    fontSize: '12px',
                    color: '#b1b3b2',
                    top: '-2rem',
                    fontWeight: 600,
                    position: 'absolute',
                    textAlign: 'center',
                    right: 0,
                    marginBottom: '4px',
                  }}
                >평균 오퍼&nbsp;{numberWithCommas(avg)}
                </span> */}
              </div>
            </div>
            <span className="progress-bar-inner" />
          </div>
        </div>
      </Styled.BarAnimation>
      <Styled.OfferRange d-flex justify-content-between>
        <div className="min">{numberWithCommas(minPrice)}원</div>
        <div className="max">{numberWithCommas(maxPrice)}원</div>
      </Styled.OfferRange>
    </>
  );
};

const Index = ({ offer }) => {
  const router = useRouter();
  const [userPoint, setUserPoint] = useState(0);
  const [rewardItem, setRewardItem] = useState([]);
  // const { data, loading } = useQuery(USER_QUERY.GET_REWARD_ITEM);
  const { auth } = useSelector((state) => state.auth);
  const scroll = useRef(null);

  useEffect(() => {
    pixelEvent('CustomizeProduct');
    localStorage.setItem('announcementDate', offer.announcementDate);
    localStorage.setItem('winnerNum', offer.winnerNum);
    window.scrollTo(0, 0);
  }, []);

  if (!offer) {
    router.back();
    return <></>;
  }

  return (
    <Styled.Container ref={scroll}>
      <Styled.Header id="header" d-flex align-items-center>
        <PrevHeader />
      </Styled.Header>
      <div>
        <CountDown endOfOffer={offer.endDate} />
        <Styled.OfferTitle style={{ marginTop: '3rem' }}>
          {convertBreakLine(offer.title2, (text, i) => (
            <React.Fragment key={i}>
              {convertStyleLine(text, (line, idx) =>
                idx % 2 ? (
                  <React.Fragment key={idx}>
                    <b style={{ color: '#dd4124' }}>{line}</b>
                  </React.Fragment>
                ) : (
                  line
                )
              )}
              <br />
            </React.Fragment>
          ))}
        </Styled.OfferTitle>
        <Styled.OfferTitleDescript>
          {convertBreakLine(offer.description2, (text, i) => (
            <React.Fragment key={i}>
              {convertBoldLine(text, (line, idx) =>
                idx % 2 ? (
                  <React.Fragment key={idx}>
                    <b>{line}</b>
                  </React.Fragment>
                ) : (
                  line
                )
              )}
              <br />
            </React.Fragment>
          ))}
        </Styled.OfferTitleDescript>
        <Styled.OfferDateInfo d-flex flex-column align-items-center>
          {/* <div className="remain">
            D-{getDDay(offer.endDate)}{' '}
            <DayTimerComponent
              init={convertDateToTime(offer.endDate)}
              onTimeOut={() => {
                alert('오퍼가 종료되었습니다.');
                router.push('/');
              }}
            />
          </div> */}
          <div className="period">
            {dayjs(offer.startDate).format('YYYY.MM.DD')}~
            {dayjs(offer.endDate).format('YYYY.MM.DD')}
          </div>
        </Styled.OfferDateInfo>

        {/* input & bar animation */}
        <div
          style={{
            marginBottom: '3rem',
          }}
        >
          <OfferBar
            avg={offer.applyAveragePrice}
            inputPrice={1000}
            minPrice={offer.minPrice}
            maxPrice={offer.maxPrice}
          />
        </div>
      </div>
      <UsingStoreForm
        offer={offer}
        minPrice={offer.minPrice}
        maxPrice={offer.maxPrice}
        avg={offer.applyAveragePrice}
      />
    </Styled.Container>
  );
};

export default Index;
