import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { APPLY_OFFER } from '../graphql/offer';
import { removeOfferForm } from '../modules/form/form.actions';
import { pixelEvent } from 'utils/pixel';
// import { pixelEvent } from '@helper/pixel';

export default function useOfferMutation(offerInput) {
  const router = useRouter();
  const id = offerInput.offerId;

  const option = {
    variables: {
      input: offerInput,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response.applyOffer) {
        pixelEvent('CompleteRegistration', {
          currency: 'KRW',
          value: 10000, // db단가가 들어가야 하는데 현재 없음
        });
        router.push({
          pathname: `/offer/${id}/done`,
        });
      }
    },
  };

  const [mutateOffer, { data, loading: mutationLoading, error: mutationError }] = useMutation(
    APPLY_OFFER,
    option
  );

  const applyOffer = useCallback(() => {
    mutateOffer();
  }, [mutateOffer]);

  return [applyOffer];
}
