import styled from 'styled-components';
import Div from 'components/atoms/div';

export const Container = styled.div`
  position: relative;
  font-size: 2rem;
  padding-top: 8.8rem;
`;

export const MyRankPeriod = styled.div`
  margin: 2rem 0 4rem;
  font-size: 1.4rem;
`;

export const MyRankTop = styled.div`
  color: #dd4124;
  margin-bottom: 2rem;
  * {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .rank-blank {
    color: #000;
    font-size: 2.4rem;
    font-weight: 600;
  }
  .rank {
    line-height: 1;
    font-weight: 600;
    font-size: 3.4rem;
  }
  .rank-text {
    color: #000;
  }
`;

export const InviteRuleText = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #b1b3b2;
`;

export const FixedBottom = styled(Div)`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  button {
    color: #fff;
    background-color: #000;
    font-size: 1.4rem;
    height: 6.4rem;
  }
`;

export const Table = styled.div`
  .table_row {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding: 2rem 0;
    border-bottom: 1px solid #dce1ea;
    box-sizing: border-box;
    .wrapper {
      width: 100%;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }

    .col {
      flex: 2 1 25%;
      -webkit-box-flex: 0;
      vertical-align: top;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
    }
    .col-rank {
      flex-basis: 20%;
    }
    .col-name {
      flex-basis: 30%;
    }
    .col-count {
      text-align: right;
      flex-basis: 20%;
    }
    .col-rewards {
      text-align: right;
      flex-basis: 30%;
      .coupon-cnt {
        color: #000;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .svg-stamp-black {
          width: 2rem;
          height: 2rem;
          margin-right: 0.5rem;
        }
        .svg-cross-black {
          width: 1rem;
          height: 1rem;
        }
        span {
          margin-left: 0.2rem;
          font-size: 1.8rem;
          font-weight: 600;
          min-width: 1.6rem;
          text-align: center;
        }
      }
    }
  }

  .table_row--header {
    border-bottom: 1px solid #000;
    padding: 1rem 0;
    .col {
      font-weight: bold;
    }
  }
  .table_row--my {
    .col {
      font-weight: bold;
      color: #dd4124;
    }
  }
`;
