import styled from 'styled-components';

const ArrowDown = 'assets/images/icons/arrow-down.svg';

export const SelectBox = styled.select`
  height: 5.6rem;
  width: 100%;
  padding: 0 1.4rem;
  outline: none;
  &::placeholder {
    color: #b1b3b2;
  }
  background: url(${ArrowDown}) no-repeat 95% 50%;
  &:invalid {
    color: #b1b3b2;
  }
  option {
    color: #000;
  }
`;
