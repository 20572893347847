import React from 'react';
import { useRouter } from 'next/router';
import * as Styled from './index.styles';

export const OfferAgreementButton = ({ handleTerm, term }) => {
  const router = useRouter();
  return (
    <Styled.Term onClick={handleTerm} term={term}>
      <Styled.Span
        onClick={(e) => {
          e.stopPropagation();
          router.push('/offer/term');
          // window.open('/offer/term');
        }}
      >
        (필수) 개인정보 수집・이용에 동의
      </Styled.Span>
    </Styled.Term>
  );
};
