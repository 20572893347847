import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { PrevHeader } from 'components';
import * as Styled from './index.styles';
import { useUser } from 'hooks/useUser';
import { webShare } from 'utils/webShare';
import { numberWithCommas } from 'utils/number';
import { convertBreakLine } from 'utils/common';
import { CloseEdgeButton } from 'components/atoms/Button';
import { removeOfferForm } from 'modules/form/form.actions';
const DecoOfferDone = '/assets/images/deco-offer-finish.png';

const Index = ({ id }) => {
  const router = useRouter();
  const { user } = useUser();
  const dispatch = useDispatch();
  const [scale, setScale] = useState(false);
  const [offerPrice, setOfferPrice] = useState(0);

  // const state = {
  //   announcementDate: 1,
  //   offerPrice: 1,
  //   winnerNum: 1,
  // };
  // const announcementDate = state?.announcementDate;
  // const winnerNum = state?.winnerNum;

  useEffect(() => {
    dispatch(
      removeOfferForm({
        key: 'OFFER_FORM',
      })
    );
    setOfferPrice(localStorage.getItem('offerPrice'));
    setTimeout(() => {
      setScale(true);
    }, 100);
    window.scrollTo(0, 0);
  }, []);

  // if (!offerPrice) {
  //   router.push('/offer');
  //   return;
  //   // return <Redirect to="/offer" />;
  // }

  const descriptText =
    '오퍼 결과는 입력하신 연락처로$n다음날 낮 12시에 연락 드리겠습니다.$n$n주말에 오퍼하신 경우$n다음주 월요일에 안내해드립니다.';
  // const descriptText2 = `결과 발표일: ${dayjs(announcementDate).format(
  //   'YYYY.MM.DD'
  // )}$n오퍼 선정 인원: ${winnerNum}명`;

  const handelButton = () => {
    webShare({
      referralCode: user?.referralCode || null,
      shareUrl: `${window.location.origin}/offer/${id}`,
    });
  };

  return (
    <Styled.Container>
      <Styled.FakeBackground />
      <Styled.Header d-flex align-items-center justify-content-between>
        <div />
        <CloseEdgeButton white pathname="/pick" replace />
      </Styled.Header>
      {/* <Styled.DecoOfferDone bgImg={DecoOfferDone} /> */}
      <Styled.ImageOfferDone src={DecoOfferDone} scale={scale} />
      <div>
        <Styled.OfferDoneTitle>
          {numberWithCommas(offerPrice)}원<br />
          오퍼되었습니다!
        </Styled.OfferDoneTitle>
        <Styled.OfferDoneTitleDescript>
          <span>
            {convertBreakLine(descriptText, (line, idx) => (
              <React.Fragment key={idx}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span>
          {/* <span>
            {convertBreakLine(descriptText2, (line, idx) => (
              <React.Fragment key={idx}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span> */}
        </Styled.OfferDoneTitleDescript>

        <Styled.FixedBottom>
          <Styled.DoneButton onClick={handelButton} bgBlack>
            친구에게 공유하기
          </Styled.DoneButton>
        </Styled.FixedBottom>
      </div>
    </Styled.Container>
  );
};

export default Index;
