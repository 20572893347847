import React from 'react';
import * as Styled from './index.styles';
import { convertOfferPriceRect } from 'utils/common';

const Index = ({ abcList, list }) => (
  <Styled.Table>
    <div className="table_row table_row--header">
      <div className="wrapper">
        {abcList.map((h, idx) => (
          <div key={idx} className={`col ${h.class}`}>
            {h.value}
          </div>
        ))}
      </div>
    </div>
    <div>
      {list.map((l, idx) => (
        <div className="table_row" key={idx}>
          <div className="wrapper">
            {
              // abcList.map((h, i) => <div key={i} className={`col ${h.class}`}>{l[h.name]}</div>)
              abcList.map((h, i) => (
                <div key={i} className={`col ${h.class}`}>
                  {convertOfferPriceRect(l[h.name], (line, z) => (
                    <React.Fragment key={z}>
                      {line}
                      {z === 0 && <span className="rect" />}
                    </React.Fragment>
                  ))}
                </div>
              ))
            }
          </div>
        </div>
      ))}
    </div>
  </Styled.Table>
);

export default Index;

export const TableWithHeader = ({ myRank, list = [], user }) => (
  <Styled.Table>
    <div className="table_row table_row--header">
      <div className="wrapper">
        <div className="col col-rank">순위</div>
        <div className="col col-name">이름</div>
        <div className="col col-count">초대친구</div>
        <div className="col col-rewards">리워드</div>
      </div>
    </div>

    <div className="table_row table_row--my">
      <div className="wrapper">
        <div className="col col-rank">{myRank?.rank || '-'}</div>
        <div className="col col-name">{myRank?.username || user.name}</div>
        <div className="col col-count">{myRank?.inviteCount || '0'}명</div>
        <div className="col col-rewards">
          <div className="coupon-cnt">
            <div className="svg-stamp-black" />
            <div className="svg-cross-black" />
            <span>{[10, 10, 10, 6, 6, 6, 3, 3, 3, 3][myRank?.rank - 1] || 0}</span>
          </div>
        </div>
      </div>
    </div>

    {list.map((r, idx) => (
      <div className="table_row" key={idx}>
        <div className="wrapper">
          <div className="col col-rank">{r.rank}</div>
          <div className="col col-name">{r.username}</div>
          <div className="col col-count">{r.inviteCount}명</div>
          <div className="col col-rewards">
            <div className="coupon-cnt">
              <div className="svg-stamp-black" />
              <div className="svg-cross-black" />
              <span>{[10, 10, 10, 6, 6, 6, 3, 3, 3, 3][r?.rank - 1] || 0}</span>
            </div>
          </div>
        </div>
      </div>
    ))}
  </Styled.Table>
);
