import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';
import { Button } from 'components/atoms/Button';

export const Error = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #dd4124;
  text-align: right;
  position: absolute;
  right: 0;
`;

export const InputGroup = styled(Div)`
  position: relative;
  .currency {
    font-family: 'saira';
    font-weight: bold;
    font-size: 2.4rem;
    position: absolute;
    left: 1.8rem;
    top: 1.2rem;
  }
  margin-bottom: 3rem;
  font-size: 1.3rem;
  color: #000;
  .label-wrap {
    label {
      font-size: 1.4rem;
    }
  }
  input {
    text-align: right;
    font-size: 2.4rem;
    font-weight: 600;
    height: 6.4rem;
    width: 100%;
    padding: 0 1.4rem;
    border-radius: 4px;
    border: solid 2px #000;
    color: ${(props) => props.zeroColor && '#e1e3e2'};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 2rem;
  padding-top: 5.8rem;
  min-height: 100vh;

  b {
    font-weight: bold;
  }
`;

export const Header = styled(Div)`
  position: absolute;
  width: 100%;
  top: 0;
  color: #000;
  height: 5.8rem;
  .tab {
    padding: 0.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .on-going {
  }
  .my-info,
  .my-counsel {
    color: #8e8e8e;
  }
  .notice {
    margin-left: auto;
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const OfferTitle = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 0.5rem;
  line-height: 1.3;
`;
export const OfferTitleDescript = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 2rem;
`;

export const OfferDateInfo = styled(Div)`
  .remain {
    color: #dd4124;
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 0;
  }
  .period {
    font-size: 1.4rem;
    letter-spacing: 0;
  }
`;

export const OfferInputWrap = styled(Div)`
  padding: 0 20px;
  border-radius: 4px;
  border: solid 2px #000;
  input {
  }
`;

export const OfferInputGroup = styled(Div)`
  flex: 1 1 0;
`;
export const ButtonWrap = styled(Div)`
  width: 100%;
  margin-top: 2rem;
`;

const BlackButton = css`
  background-color: #000;
  border: none;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

export const FixedBottom = styled(Div)`
  width: 100%;
  max-width: 420px;
  margin: 0 -2rem;
  padding: 0 2rem;
  position: absolute;
  bottom: 3rem;
  text-align: center;
  button {
    color: #fff;
    background-color: #000;
    font-size: 1.4rem;
    height: 6.4rem;
    border-radius: 4px;
  }
`;

export const BasicButton = styled(Button)`
  border-color: #000;
  color: #000;
`;

export const StampButton = styled(Button)`
  background-color: #000;
  border: none;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;

export const InviteButton = styled(Button)`
  margin-top: 2rem;
  border-color: #fff;
`;

export const TitleBig = styled.h1`
  font-size: 4rem;
`;

export const Image = styled.div``;

export const Title = styled.div``;

export const Description = styled.div``;

export const HospitalName = styled.div``;

export const Count = styled.div``;

export const OfferRange = styled(Div)`
  font-size: 1.4rem;
  font-weight: 600;
  .min {
    font-weight: 600;
  }
  .max {
    font-weight: 600;
  }
`;

export const BarAnimation = styled.div`
  margin-top: 6rem;
  margin-bottom: 0.5rem;

  @property --num {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
  }

  @keyframes animate-stripes {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 60px 0;
    }
  }

  @keyframes auto-progress {
    0% {
      width: 0%;
    }

    100% {
      width: ${(props) => props.avgRate}%;
    }
  }

  @keyframes counter {
    from {
      --num: 0;
    }
    to {
      --num: ${(props) => props.avg};
    }
  }

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .avg-offer-price {
    animation: opacity 2s 1 ease-in-out;
    /* counter-reset: num var(--num);
  animation: counter 1.3s 1 ease, opacity 2s 1 ease-in-out;
  animation-fill-mode: forwards; */
    /* animation-delay: .1s; */
  }
  .avg-offer-price::after {
    /* content: counter(num); */
  }

  .progress-bar {
    &.hidden {
      height: 0px;
    }
    &.border-radius {
      overflow: hidden;
      border-radius: 0 4.5px 4.5px 0;
    }

    position: relative;
    background-color: #f3f3f3;
    height: 10px;
    width: 100%;
  }

  .pos-relative {
    position: relative;
  }

  .stripes {
    background-size: 30px 30px;
    background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }

  .stripes.animated {
    animation: animate-stripes 0.6s linear infinite;
  }

  .stripes.animated.slower {
    animation-duration: 1.25s;
  }

  .stripes.reverse {
    animation-direction: reverse;
  }

  .progress-bar-inner {
    display: block;
    height: 10px;
    width: 0%;
    width: ${(props) => props.rate}%;
    background-color: ${(props) => (props.error ? '#dd4124' : '#000')};
    position: relative;
    transition: width 0.4s, background-color 0.2s;
    transition-delay: 0.3s;
    /* animation: auto-progress 10s infinite linear; */
  }
  .progress-bar-avg {
    position: absolute;
    display: block;
    height: 10px;
    width: ${(props) => props.avgRate}%;
    background-color: #e1e3e2;
    /* position: relative; */
    animation: auto-progress 1.3s ease, animate-stripes 0.6s linear infinite;
  }
`;
