import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

import { getAllSymptoms } from 'graphql/philing';
// import { getCollection, getEvent } from '../modules/offer/offer.actions';

export default function useAllSymptoms() {
  const { data, loading } = useQuery(getAllSymptoms);

  return { data, loading };
}
