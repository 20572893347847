import { useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';

import { getQuestion } from 'graphql/philing';
// import { getCollection, getEvent } from '../modules/offer/offer.actions';

export default function useQuestion({ symptomId, order = 1, onComplete }) {
  const options = {
    // fetchPolicy: 'network-only',
    variables: {
      order,
      symptomId,
    },
    onCompleted: (res) => {
      onComplete(res.medicalQuestion);
    },
  };
  const { data, loading, refetch } = useQuery(getQuestion, options);

  let question = null;
  if (!loading && data.medicalQuestion) {
    question = Object.assign({}, data.medicalQuestion);
    const ops = [...question.options];
    question.options = orderBy(ops, ['order'], ['asc']);
  }

  return { question, loading, refetch };
}

// "data": {
//   "medicalQuestion": {
//     "id": "1",
//     "type": {
//       "id": "1",
//       "name": "선다형(복수)",
//       "description": null
//     },
//     "required": true,
//     "options": [
//       {
//         "id": "1",
//         "title": "헤어라인 탈모",
//         "description": null,
//         "imageUrl": null,
//         "order": 1,
//         "type": {
//           "id": "1",
//           "name": "이미지",
//           "description": null
//         }
//       },
//       {
//         "id": "2",
//         "title": "정수리 탈모",
//         "description": null,
//         "imageUrl": null,
//         "order": 2,
//         "type": {
//           "id": "1",
//           "name": "이미지",
//           "description": null
//         }
//       },

//     ]
//   }
// }
